<template>
    <div>
        <Banner />
        <div class="mob-feature bg-light">
            <div class="section-gap">
                <div class="container">
                    <MobileFeature :data="data.data1" />
                </div>
            </div>
        </div>
	    <div class="chk-video-section section-gap ">
            <div class="container">
                <VideoSectionV1 :videoContent="data.data2" />
            </div>
        </div>

        <div class="contact-section">
            <ContactInfo :data="data.dataContact"></ContactInfo>
        </div>

        <div class="contact-section">
            <EuFooter></EuFooter>
        </div>
    </div>
</template>

<script>
import Banner from "@/components/Banner/Banner.vue";
import MobileFeature from '@/components/Sections/MobileFeature.vue';
import VideoSectionV1 from "@/components/Sections/VideoSectionV1.vue";
import ContactInfo from "Components/Sections/ContactInfo.vue";
import EuFooter from './EuFooter.vue'

import data from './domov.json'

export default {
    components: { Banner, MobileFeature, VideoSectionV1, ContactInfo, EuFooter },

    data() {
        return {
            data: data
        }
    }
}
</script>