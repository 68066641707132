<template>
  <!-- Mobile Feature -->
  <div class="row align-items-center">
    <div class="col-sm-12 col-md-12 col-lg-6 text-center col-md-space">
		 <div class="show-img">
		 	<div class="top-left-bg"></div>
					<div class="bottom-right-bg"></div>
      <img
        src="/static/img/1_home.jpg?a=2"
        alt="mobile feature"
        class="img-fluid"
        width="680"
        height="780"
      />
		</div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6">
      <h2 class="font-weight-bold mb-4">{{data.title}}</h2>
      <p class="lead" v-if="data.mainText">{{data.mainText}}</p>
      <p class="mb-4" v-if="data.subText">{{data.subText}}</p>
      <router-link
          class="btn btn-primary px-4 py-2 font-weight-bold"
          to="o-afriskem-somu"
      >
        Več o afriškem somu
      </router-link>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    data: { type: Object },
  },
};
</script>