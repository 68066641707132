<template>
  <div class="contact-section py-6">
    <div class="container">
      <div class="section-title-wrapper">
        <div class="row">
          <div class="col-sm-12 col-md-6 mx-auto text-center">
            <h2>{{data.title}}</h2>
            <p v-if="data.subtitle" class="lead">{{data.subtitle}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="g-map-custom">
      <template>
        <div>
          <iframe width="100%" height="500" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
            :src="`https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=sl&amp;q=${data.location.fullAddress}&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`"></iframe>
          <!-- <gmap-map
            :center="center"
            :zoom="9"
            style="width: '300px'; height: 520px"
            class="gmap_canvas"
          >
            <gmap-marker
              :position="markers.position"
              :clickable="true"
              :draggable="false"
              @click="center=markers.position"
            ></gmap-marker>
          </gmap-map> -->
        </div>
      </template>
    </div>
    <div class="container">
      <div class="row row-parent">
        <div class="col-sm-12 col-md-10 col-lg-9 contact-block mx-auto">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-6 col-xl-6 contact-location">
              <div class="d-flex">
                <div class="mr-4">
                  <i class="ion-ios-pin font-3x text-warning"></i>
                </div>
                <div class>
                  <h4 class="mb-2">{{data.location.title}}</h4>
                  <div class="mr-4 mb-1">{{data.location.address}}</div>
                  <div class="mr-4">{{data.location.town}}</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xl-6 contact-contact">
              <div class="d-flex">
                <div class="mr-4">
                  <i class="ion-ios-chatboxes font-3x text-warning"></i>
                </div>
                <div class>
                  <h4 class="mb-2">{{data.contact.title}}</h4>
                  <span>
                    <div class="mb-1">{{data.contact.phone}}</div>
                  </span>
                  <span>
                    <div>{{data.contact.email}}</div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import info from "Components/data/contact.json";
// import Map from 'Components/GoogleMap/Map';
// import { mapSettings } from "Constants/mapSettings";

export default {
  // name: "google-maps",
  props: {
    data: { type: Object }
  },

  data() {
    return {
      info,
      center: { lat: 30.7, lng: 76.7 },
      markers: {
        position: { lat: 30.7, lng: 76.7 }
      }
    };
  }
};
</script>